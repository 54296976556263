import React from 'react';

const Leaf = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    width="63"
    height="80"
    viewBox="0 0 63 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.1334 1.13335C18.1334 3.53335 0.666687 19 0.666687 36.3334C0.666687 41.2667 6.00002 52.7334 9.86669 56.2C12.2667 58.3334 12.6667 60.2 12.6667 69.2667C12.6667 77.6667 13.0667 79.6667 14.6667 79.6667C16.2667 79.6667 16.6667 77.6667 16.6667 70.2C16.6667 61.1334 16.8 60.8667 19.7334 61.8C30.5334 65.4 45.8667 60.0667 50.9334 50.8667C53.6 46.3334 54 43.6667 54 30.6C54 14.8667 54.6667 12.0667 60.4 6.06668C62.9334 3.26668 63.3334 2.33335 61.8667 1.80002C57.7334 0.466683 48.8 0.200016 40.1334 1.13335ZM37.4667 9.13335C26.5334 16.4667 18.8 27.5334 14.6667 42.0667L11.7334 52.0667L9.33335 48.6C5.86669 43.1334 4.53335 35.8 6.13335 29.6667C8.40002 21.4 19.6 11.1334 30.2667 7.80002C42.6667 3.93335 44.9334 4.33335 37.4667 9.13335ZM51.3334 10.4667C50.2667 12.3334 49.7334 19 49.8667 28.8667C50 42.0667 49.6 45 47.3334 48.6C42.5334 56.6 29.3334 60.6 20 57C16.5334 55.6667 16.4 55.2667 17.2 49.2667C18.5334 40.3334 26.1334 25.2667 32.9334 18.7334C38.1334 13.8 51.2 5.80002 52.4 7.00002C52.6667 7.26668 52.1334 8.73335 51.3334 10.4667Z"
      fill="currentColor"
    />
  </svg>
);

export default Leaf;
